const GRNCard = ({ grn }) => (
    <div className="border p-4 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-2">GRN ID: {grn.grn_id}</h3>
        <p>Created At: {new Date(grn.create_at).toLocaleDateString()}</p>
        <p>Created By: {grn.create_by}</p>
        <h4 className="mt-2 font-semibold">Items:</h4>
        <ul className="list-disc pl-4">
            {grn.items.map((item) => (
                <li key={item.item_id}>
                    {item.item_name} - Qty: {item.total_qty}, Amount: {item.total_amount.toFixed(2)}
                </li>
            ))}
        </ul>
        <p className="mt-2 font-semibold">Total Amount: {grn.total_amount.toFixed(2)}</p>
    </div>
);

export default GRNCard;
