import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AssignRooster = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAssignRosterModal, setShowAssignRosterModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formData, setFormData] = useState({
    date: '',
    name_of_work: '',
    reason: '',
    start_time: '',
    end_time: '',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [assignRosterForms, setAssignRosterForms] = useState([
    { startDate: '', endDate: '', timetableID: '' }
  ]);
  const [timetables, setTimetables] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    const fetchTimetableData = async () => {
      try {
        const response = await axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/timetable/gettimetable');
        setTimetables(response.data);
      } catch (error) {
        console.error("Error fetching timetable data:", error);
      }
    };
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/employees/getemployeebasicdetails');
        setEmployeeData(response.data);
        setFilteredEmployeeData(response.data); // Set filtered data initially
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchTimetableData();
    fetchEmployeeData();
  }, []);

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
    setFormData({
      date: '',
      name_of_work: '',
      reason: '',
      start_time: '',
      end_time: '',
    });
    setSelectedEmployees([]);
  };

  const openAssignRosterModal = (employee) => {
    setSelectedEmployee(employee);
    setShowAssignRosterModal(true);
  };

  const closeAssignRosterModal = () => {
    setShowAssignRosterModal(false);
    setAssignRosterForms([{ startDate: '', endDate: '', timetableID: '' }]);
    setSelectedEmployee(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query) {
      const filteredData = employeeData.filter(employee =>
        employee.employee_name_initial.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredEmployeeData(filteredData);
    } else {
      setFilteredEmployeeData(employeeData);
    }
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployees((prevSelected) => {
      const isSelected = prevSelected.some((e) => e.employee_no === employee.employee_no);
      return isSelected
        ? prevSelected.filter((e) => e.employee_no !== employee.employee_no)
        : [...prevSelected, employee];
    });
  };

  const handleSubmit = () => {
    console.log("Form submitted", formData, selectedEmployees);
    closeModal();
  };
  const [rosterData, setRosterData] = useState([]);

  const handleAssignRosterSubmit = async () => {
    try {
      const employeesData = selectedEmployees.map((employee) => ({
        employee_no: employee.employee_no,
        rosterWeeks: assignRosterForms.map((form) => ({
          startDate: form.startDate,
          endDate: form.endDate,
          timetable_id: parseInt(form.timetableID, 10),
        })),
      }));
      const postData = { employees: employeesData };

      const response = await axios.post(
        'https://back-ayurveda.erp-quortech.com/v1/hris/timetable/addRosterTimetableForEmployees',
        postData
      );

      if (response.status === 200 && response.data.message) {
        console.log("Roster assigned successfully:", response.data.message);
        setShowSuccessPopup(true);  // Show the success popup
      }

      closeAssignRosterModal();
    } catch (error) {
      console.error("Error assigning roster:", error.response ? error.response.data : error.message);
    }
  };


  useEffect(() => {
    const fetchRosterData = async () => {
      try {
        const response = await axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/timetable/Get-Roster');
        setRosterData(response.data.employeesWithRosters);
      } catch (error) {
        console.error("Error fetching roster data:", error);
      }
    };

    fetchRosterData();
  }, []);



  const handleAddRosterForm = () => {
    setAssignRosterForms([...assignRosterForms, { startDate: '', endDate: '', timetableID: '' }]);
  };

  const handleRemoveRosterForm = (index) => {
    const updatedForms = assignRosterForms.filter((_, i) => i !== index);
    setAssignRosterForms(updatedForms);
  };

  const handleRosterFormChange = (index, field, value) => {
    const updatedForms = assignRosterForms.map((form, i) =>
      i === index ? { ...form, [field]: value } : form
    );
    setAssignRosterForms(updatedForms);
  };

  return (
    <div className='bg-background h-screen overflow-y-auto'>
      <div className='mx-5 my-5'>
        <p className='text-[25px] font-bold'>Assign Roster</p>
        <button onClick={openModal} className='bg-primary p-2 rounded-[20px] text-white mt-5'>+ Assign Roster</button>

        {/* <div className='mt-5'>
          <input
            className='bg-white rounded-[35px] p-2 w-[400px]'
            placeholder='Search by the Roster Timetable name'
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div> */}

        <div className='mt-8'>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee No</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Assigned date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
              </tr>
            </thead>
            <tbody>
              {rosterData.map((employee, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm">{employee.employee_no}</td>
                  <td className="px-6 py-4 text-sm">{employee.employee_name_initial}</td>
                  <td className="px-6 py-4 text-sm">{new Date(employee.AssignedDate).toLocaleDateString()}</td>
                  <td className="px-6 py-4 text-sm">
                    <div className='flex items-center gap-2'>
                      <button className="bg-primary text-white px-4 py-1 rounded-lg">Edit</button>
                      <button className="bg-red-600 text-white px-4 py-1 rounded-lg">View</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>

      {/* Modal Popup for Assigning Roster */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-[1200px] p-8 rounded-lg shadow-lg overflow-y-auto">
            {/* Assigning roster modal content */}
            <div className="flex justify-between items-center mb-5">
              <h2 className="text-2xl font-semibold">Employee Assigning</h2>
              <button onClick={closeModal} className="text-gray-600 text-2xl font-bold">&times;</button>
            </div>
            <div className="bg-white p-5 rounded-lg shadow-lg mt-8">
              <div className="mt-6">
                <div className="mt-4">
                  <input
                    type="text"
                    placeholder="Search employee by name"
                    className="input rounded-xl border border-gray-300 h-10 px-4 py-2 mb-4 placeholder-gray-400"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <div className="overflow-y-auto max-h-64">
                    <table className="table-auto w-full">
                      <thead>
                        <tr>
                          <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">Employee ID</th>
                          <th className="px-4 py-2 bg-[#F5F5F5]">Employee Name</th>
                          <th className="px-4 py-2 bg-[#F5F5F5] rounded-r-xl">Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredEmployeeData.map((employee) => (
                          <tr key={employee.employee_no} className="border-b border-gray-300">
                            <td className="px-4 py-2">{employee.employee_no}</td>
                            <td className="px-4 py-2">{employee.employee_name_initial}</td>
                            <td className="px-4 py-2 text-center">
                              <input
                                type="checkbox"
                                onChange={() => handleSelectEmployee(employee)}
                                checked={selectedEmployees.some(
                                  (e) => e.employee_no === employee.employee_no
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl font-bold text-[#344054] mb-4">Selected Employees</h3>
                  <div className="overflow-y-auto max-h-64">
                    <table className="table-auto w-full">
                      <thead>
                        <tr>
                          <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">Employee ID</th>
                          <th className="px-4 py-2 bg-[#F5F5F5]">Employee Name</th>
                          <th className="px-4 py-2 bg-[#F5F5F5]">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedEmployees.map((employee) => (
                          <tr key={employee.employee_no} className="border-b border-gray-300">
                            <td className="px-4 py-2">{employee.employee_no}</td>
                            <td className="px-4 py-2">{employee.employee_name_initial}</td>
                            <td className="px-4 py-2">
                              <button
                                className='bg-primary p-1 text-white rounded-lg'
                                onClick={() => openAssignRosterModal(employee)}
                              >
                                + Assign Roster
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-between mt-4">
                  <button
                    onClick={handleSubmit}
                    className="bg-primary text-white px-4 py-2 rounded-[22px]"
                  >
                    Assign
                  </button>
                  <button onClick={closeModal} className="bg-[#797C80] text-white px-4 py-2 rounded-[22px]">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Assign Roster Popup */}
      {showAssignRosterModal && selectedEmployee && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
          <div className="bg-white w-[500px] p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Assign Roster</h2>
            <div className='grid grid-cols-2 grid-flow-row gap-4'>
              <div className="mb-3">
                <label>Employee Name:</label>
                <input
                  type="text"
                  value={selectedEmployee.employee_name_initial}
                  readOnly
                  className="border border-gray-300 rounded p-2 w-full"
                />
              </div>
              <div className="mb-3">
                <label>Employee ID:</label>
                <input
                  type="text"
                  value={selectedEmployee.employee_no}
                  readOnly
                  className="border border-gray-300 rounded p-2 w-full"
                />
              </div>
            </div>

            {assignRosterForms.map((form, index) => (
              <div key={index} className="mb-6 border p-4 rounded-lg">
                <div className="mb-3">
                  <label>Start Date:</label>
                  <input
                    type="date"
                    className="border border-gray-300 rounded p-2 w-full"
                    value={form.startDate}
                    onChange={(e) => handleRosterFormChange(index, 'startDate', e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label>End Date:</label>
                  <input
                    type="date"
                    className="border border-gray-300 rounded p-2 w-full"
                    value={form.endDate}
                    onChange={(e) => handleRosterFormChange(index, 'endDate', e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label>Timetable:</label>
                  <select
                    className="border border-gray-300 rounded p-2 w-full"
                    value={form.timetableID}
                    onChange={(e) => handleRosterFormChange(index, 'timetableID', e.target.value)}
                  >
                    <option value="" disabled>Select a timetable</option>
                    {timetables.map((timetable) => (
                      <option key={timetable.TimetableID} value={timetable.TimetableID}>
                        {timetable.TimetableName} ({timetable.StartCheckInTime} - {timetable.EndCheckOutTime})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => handleRemoveRosterForm(index)}
                    className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}

            <div className="flex justify-end mb-4">
              <button
                type="button"
                onClick={handleAddRosterForm}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                + Add Another Roster
              </button>
            </div>

            <div className="flex justify-end mt-4">
              <button onClick={closeAssignRosterModal} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">
                Cancel
              </button>
              <button onClick={handleAssignRosterSubmit} className="bg-blue-500 text-white px-4 py-2 rounded">
                Assign
              </button>
            </div>
          </div>
        </div>


      )}

      {showSuccessPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-[400px] p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4">Success!</h2>
            <p className="text-lg mb-4">Roster timetable assigned successfully.</p>
            <button
              onClick={() => setShowSuccessPopup(false)}  // Close the popup
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              OK
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default AssignRooster;
