import React, { useState } from 'react';

const CreateRooster = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className='bg-background h-screen overflow-y-auto'>
            <div className='mx-5 my-5'>
                <div>
                    <p className='text-[25px] font-bold '> Create Roster</p>
                    <button onClick={openModal} className='bg-primary p-2 rounded-[20px] text-white mt-5'>+ Create Roster</button>
                    <div className='mt-5'>
                        <input className='bg-white rounded-[35px] p-2 w-[400px]' placeholder='Search by the Roster Timetable name' />
                    </div>
                </div>

                <div className='mt-8'>
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Roster Timetable Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check in Start Time</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check in End Time</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check out Start Time</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check out End Time</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Required Working Days</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b hover:bg-gray-50">
                                <td className="px-6 py-4 text-sm"></td>
                                <td className="px-6 py-4 text-sm"></td>
                                <td className="px-6 py-4 text-sm"></td>
                                <td className="px-6 py-4 text-sm"></td>
                                <td className="px-6 py-4 text-sm"></td>
                                <td className="px-6 py-4 text-sm"></td>
                                <td className="px-6 py-4 text-sm">
                                    <div className='flex items-center gap-2'>
                                        <button className="bg-primary text-white px-4 py-1 rounded-lg">Edit</button>
                                        <button className="bg-red-600 text-white px-4 py-1 rounded-lg">Delete</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal Popup for Creating Roster */}
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-[1200px] p-8 rounded-lg shadow-lg">
                        <div className="flex justify-between items-center mb-5">
                            <h2 className="text-2xl font-bold">Create Roster Section</h2>
                            <button onClick={closeModal} className="text-gray-600 text-2xl font-bold">&times;</button>
                        </div>
                        <div className="grid grid-cols-2 gap-8">
                            <div className="col-span-2 flex items-center">
                                <label className="w-1/3">Roster Timetable Name:</label>
                                <input type="text" className="border border-gray-300 rounded p-2 w-full" />
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/2">Check in start time:</label>
                                <input type="time" className="border border-gray-300 rounded p-2 w-full" />
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/2">Check in end time:</label>
                                <input type="time" className="border border-gray-300 rounded p-2 w-full" />
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/2">Check out start time:</label>
                                <input type="time" className="border border-gray-300 rounded p-2 w-full" />
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/2">Check out end time:</label>
                                <input type="time" className="border border-gray-300 rounded p-2 w-full" />
                            </div>
                            <div className="col-span-2 flex items-center">
                                <label className="w-1/3">Required Working Days:</label>
                                <input type="number" className="border border-gray-300 rounded p-2 w-full" />
                            </div>
                        </div>
                        <div className="flex justify-end mt-5">
                            <button onClick={closeModal} className="bg-green-600 text-white px-4 py-2 rounded">Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CreateRooster;
