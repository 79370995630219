import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import AccountsTable from './AccountsTable';

const ProfitAndLoss = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [data, setData] = useState([]);
    const [incomeTotal, setIncomeTotal] = useState(0);
    const [expenseTotal, setExpenseTotal] = useState(0);
    const [profitOrLoss, setProfitOrLoss] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterDates, setFilterDates] = useState({ start: null, end: null });

    const fetchProfitAndLossData = async () => {
        try{
            const response = await axios.get(`${backendUrl}/financeReports/getProfitAndLossReport`, {
                params: { start_date: filterDates.start, end_date: filterDates.end },
            });
            setData(response.data.structuredData);
            setIncomeTotal(response.data.incomeTotal);
            setExpenseTotal(response.data.expenseTotal);
            setProfitOrLoss(response.data.profitOrLoss);
        } catch (err) {
            console.error("Error fetching Profit And Loss Report", err);
        }
    };

    useEffect(() => {
        fetchProfitAndLossData();
    }, [filterDates]);

    const applyDateFilter = () => {
        setFilterDates({ start: startDate, end: endDate });
    };

    return (
        <div className="max-w-7xl mx-auto px-6 py-8">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Profit and Loss Statement</h1>

            <div className="flex gap-4 mb-6">
                <div className="bg-white shadow-md rounded-lg p-4 w-1/3">
                    <h3 className="font-medium text-gray-700">Total Income</h3>
                    <p className="text-2xl font-bold">{incomeTotal} LKR</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4 w-1/3">
                    <h3 className="font-medium text-gray-700">Total Expense</h3>
                    <p className="text-2xl font-bold">{expenseTotal} LKR</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4 w-1/3">
                    <h3 className="font-medium text-gray-700">Profit for the Year</h3>
                    <p className="text-2xl font-bold">{profitOrLoss} LKR</p>
                </div>
            </div>

            <div className="flex gap-4 mb-6">
                <input
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                />
                <input
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                />
                <button
                    onClick={applyDateFilter}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Apply Filter
                </button>
                <button
                    onClick={() => downloadReportAsExcel(data)}
                    className="px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-600 ml-auto"
                >
                    Download as Excel
                </button>
            </div>

            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                <AccountsTable data={data}/>
            </div>

        </div>
    );
};

const flattenData = (data) => {
    const flatData = [];

    const recursivelyFlatten = (items) => {
        items.forEach((item) => {
            // Only include account_name and amount in the export
            flatData.push({
                account_name: item.account_name,
                amount: item.amount,
            });

            // Recursively add child accounts, if any
            if (item.children && item.children.length > 0) {
                recursivelyFlatten(item.children);
            }
        });
    };

    recursivelyFlatten(data);
    return flatData;
};

const downloadReportAsExcel = (data) => {
    const flattenedData = flattenData(data); // Flatten and filter the data for export
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ProfitAndLoss');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'ProfitAndLossReport.xlsx');
};


export default ProfitAndLoss;
