import React, { useState, useEffect } from 'react';

const AccountsTable = ({ data, level = 0 }) => {
    const [expandedRows, setExpandedRows] = useState({});

    // Recursively set expanded state for all rows, including children
    const expandAllRows = (records) => {
        const expanded = {};
        const recursiveExpand = (recordList) => {
            recordList.forEach((record) => {
                expanded[record.account_id] = true;
                if (record.children && record.children.length > 0) {
                    recursiveExpand(record.children);  // Expand children recursively
                }
            });
        };

        recursiveExpand(records);
        return expanded;
    };

    useEffect(() => {
        const expanded = expandAllRows(data);  // Expand all rows on initial render
        setExpandedRows(expanded);
    }, [data]);

    const toggleRowExpansion = (accountId) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [accountId]: !prevState[accountId]
        }));
    };

    return (
        <table className="w-full border border-gray-200 text-left">
            {level === 0 && (
                <thead>
                <tr className="bg-gray-100">
                    <th className="px-4 py-2 border-b text-center">Account</th>
                    <th className="px-4 py-2 border-b text-center">Amount</th>
                </tr>
                </thead>
            )}
            <tbody>
            {data.map((record) => (
                <React.Fragment key={record.account_id}>
                    <tr className="border-b">
                        <td className="px-4 py-2" style={{paddingLeft: `${level * 20}px`}}>
                            {record.children && record.children.length > 0 && (
                                <button
                                    onClick={() => toggleRowExpansion(record.account_id)}
                                    className="mr-2 focus:outline-none"
                                >
                                    {expandedRows[record.account_id] ? '▼' : '▶'}
                                </button>
                            )}
                            {record.account_name}
                        </td>
                        <td className="px-4 py-2 text-left">
                            <div className="flex justify-end">
                                {parseFloat(record.amount).toLocaleString()} LKR
                            </div>
                        </td>
                    </tr>
                    {expandedRows[record.account_id] && record.children && (
                        <tr>
                            <td colSpan="2" className="p-0">
                                {/* Recursive rendering of child accounts */}
                                <AccountsTable data={record.children} level={level + 1} />
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            ))}
            </tbody>
        </table>
    );
};

export default AccountsTable;
