import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AttendanceEditApproval = () => {
    const [attendanceData, setAttendanceData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedAttendance, setSelectedAttendance] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        // Fetch data from the specified endpoint
        axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/leave/getleaveapprove1')
            .then(response => setAttendanceData(response.data))
            .catch(error => console.error("Error fetching attendance data:", error));
    }, []);

    const handleViewClick = (attendance) => {
        setSelectedAttendance(attendance);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedAttendance(null);
        setFile(null);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <div className='bg-background overflow-y-auto h-screen'>
            <div className='mx-6 mt-5'>
                <p className='text-[20px] font-bold mb-5'>Attendance Edit</p>
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Edited Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID of Editor</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID of Edited Employee</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Department</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Old Attendance Value</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">New Attendance Value</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceData.map((data) => (
                            <tr key={data.id} className="border-b hover:bg-gray-50">
                                <td className="px-6 py-4 text-sm">{data.requested_date}</td>
                                <td className="px-6 py-4 text-sm">{data.supervisor_id || 'N/A'}</td>
                                <td className="px-6 py-4 text-sm">{data.employee_no}</td>
                                <td className="px-6 py-4 text-sm">{data.department}</td>
                                <td
                                    className="px-6 py-4 text-sm text-primary font-bold cursor-pointer"
                                    onClick={() => handleViewClick({ ...data, type: 'old' })}
                                >
                                    View
                                </td>
                                <td
                                    className="px-6 py-4 text-sm text-primary font-bold cursor-pointer"
                                    onClick={() => handleViewClick({ ...data, type: 'new' })}
                                >
                                    View
                                </td>
                                <td className="px-6 py-4 text-sm">{data.reason}</td>
                                <td className="px-6 py-4 text-sm">
                                    <div className='flex items-center gap-2'>
                                        <button className="bg-primary text-white px-4 py-1 rounded-lg">Approve</button>
                                        <button className="bg-gray-600 text-white px-4 py-1 rounded-lg">Reject</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for Viewing Attendance Details */}
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-[400px] p-5 rounded-lg shadow-lg">
                        <h2 className="text-center text-lg font-bold mb-4">Attendance Details</h2>
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between">
                                <label>Employee ID :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.employee_no || ''}
                                    readOnly
                                />
                            </div>
                            <div className="flex justify-between">
                                <label>Employee Name :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.employee_fullname || ''}
                                    readOnly
                                />
                            </div>
                            <div className="flex justify-between">
                                <label>{selectedAttendance?.type === 'old' ? 'Old' : 'New'} Check In Time :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.checkInTime || 'N/A'}
                                    readOnly
                                />
                            </div>
                            <div className="flex justify-between">
                                <label>{selectedAttendance?.type === 'old' ? 'Old' : 'New'} Check Out Time :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.checkOutTime || 'N/A'}
                                    readOnly
                                />
                            </div>
                            {/* File Upload Section */}
                            <div className="flex justify-between items-center mt-3">
                                <label className='text-[15px] font-bold'>Upload Document:</label>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="border rounded p-1"
                                />
                            </div>
                            {file && <p className="text-sm text-gray-600 mt-2">Selected file: {file.name}</p>}
                        </div>

                        <div className="flex justify-center mt-5">
                            <button
                                onClick={closeModal}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AttendanceEditApproval;
